/**
 * getHeight - for elements with display:none
 */
function getHeight(el) {
  const elStyle = window.getComputedStyle(el);
  const elDisplay = elStyle.display;
  const elPosition = elStyle.position;
  const elVisibility = elStyle.visibility;
  const elMaxHeight = elStyle.maxHeight.replace('px', '').replace('%', '');
  let wantedHeight = 0;

  // if its not hidden we just return normal height
  if (elDisplay !== 'none' && elMaxHeight !== '0') {
    return el.scrollHeight;
  }

  // the element is hidden so:
  // making the el block so we can meassure its height but still be hidden
  el.style.position = 'absolute';
  el.style.visibility = 'hidden';
  el.style.display = 'block';

  wantedHeight = el.scrollHeight;

  // reverting to the original values
  el.style.display = elDisplay;
  el.style.position = elPosition;
  el.style.visibility = elVisibility;

  return wantedHeight;
}

/**
 * toggleSlide mimics the jQuery version of slideDown and slideUp
 * all in one function comparing the max-heigth to 0
 */
export default function toggleSlide(el) {
  let elMaxHeight = 0;

  if (el.getAttribute('data-max-height')) {
    // we've already used this before, so everything is setup
    if (el.style.maxHeight.replace('px', '').replace('%', '') === '0') {
      el.style.maxHeight = el.getAttribute('data-max-height');
    } else {
      el.style.maxHeight = '0';
    }
  } else {
    elMaxHeight = `${getHeight(el)}px`;
    el.style.transition = 'max-height 0.3s ease-in-out';
    el.style.overflowY = 'hidden';
    el.style.maxHeight = '0';
    el.setAttribute('data-max-height', elMaxHeight);
    el.style.display = 'block';

    // we use setTimeout to modify maxHeight later than display (to we have the transition effect)
    setTimeout(function() {
      el.style.maxHeight = elMaxHeight;
    }, 10);
  }
}
