import axios from 'axios';
import IMask from 'imask';
import qs from 'qs';
import Validator from 'validatorjs';
import MicroModal from 'micromodal';
import { throttle } from 'underscore';

import toggleSlide from './slide';
import scrollTo from './scrollto';
import { removeError, showSuccess, showError } from './utils';

MicroModal.init();

const bookBtns = document.querySelectorAll('.js-book');
if (bookBtns.length > 0) {
  bookBtns.forEach(btn => {
    btn.addEventListener('click', evt => {
      evt.preventDefault();

      sessionStorage.setItem('current-tour', btn.dataset.tourName);
      MicroModal.show('modal-book', {
        onShow: modal => {
          const msgCtrl = modal.querySelector('#id_message');
          msgCtrl.focus();
        },
        disableScroll: true,
      });
    });
  });
}

const closeBook = document.querySelector('.js-book-close');
closeBook.addEventListener('click', evt => {
  evt.preventDefault();

  MicroModal.close('modal-book');
});

axios.interceptors.request.use(request => {
  if (request.data && request.headers.post['Content-Type'] === 'application/x-www-form-urlencoded') {
    request.data = qs.stringify(request.data);
  }
  return request;
});

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';

const form = document.forms['contacts-form'];

const tour = document.querySelector('#id_tour');
const message = document.querySelector('#id_message');
const name = document.querySelector('#id_name');
const email = document.querySelector('#id_email');
const phone = document.querySelector('#id_phone');
const fz = document.querySelector('#id_fz');
const submit = document.querySelector('#id_submit');

const formFields = [message, name, email, phone, fz];

const phoneMask = IMask(phone, {
  mask: '+{7} (000) 000-00-00',
  lazy: false,
});

formFields.forEach(el => {
  el.addEventListener('input', () => {
    submit.disabled = !(message.value && name.value && email.value && fz.checked === true);
    // phoneMask.unmaskedValue.length === 11
  });
});

formFields.forEach(el => {
  removeError(el);
});

form.addEventListener('submit', e => {
  e.preventDefault();

  const validation = new Validator(
    {
      message: message.value,
      name: name.value,
      email: email.value,
      fz: fz.checked,
    },
    {
      message: 'required',
      name: 'required',
      email: 'email|required',
      fz: 'accepted',
    }
  );

  if (validation.passes()) {
    const action = form.getAttribute('action');

    const currentTour = sessionStorage.getItem('current-tour');
    if (currentTour) {
      tour.value = currentTour;
    } else {
      tour.value = 'Error. Tour name invalid';
    }

    axios
      .post(action, {
        tour: tour.value,
        message: message.value,
        name: name.value,
        email: email.value,
        phone: phoneMask.unmaskedValue,
        fz: fz.checked,
      })
      .then(response => {
        formFields.forEach(el => {
          el.value = '';
        });

        sessionStorage.removeItem('current-tour');
        submit.disabled = true;

        showSuccess('Ваше сообщение отправленно. Мы обязательно с Вами свяжемся.');
        MicroModal.close('modal-book');
        ym(56020297, 'reachGoal', 'request');
      })
      .catch(error => {
        showError('Произошла ошибка. Попробуйте отправить заявку позже.');
      });
  } else {
    showError('Форма заполнена не верно. Попробуйте ещё раз.');
  }
});

let prevDayIndex = -1;

const days = document.querySelectorAll('.js-day-bt');
if (days.length > 0) {
  days.forEach((day, dayIndex) => {
    let offset;
    const dayHeader = day.querySelector('.js-day-bt-header');
    if (dayHeader) {
      dayHeader.addEventListener('click', evt => {
        toggleSlide(day.querySelector('.js-day-bt-body'));

        days.forEach(innerDay => {
          const dayBody = innerDay.querySelector('.js-day-bt-body');

          if (innerDay.classList.contains('js-day-bt--open') && innerDay !== day) {
            toggleSlide(dayBody);
            offset = dayBody.getAttribute('data-max-height');
            innerDay.classList.remove('js-day-bt--open');
            innerDay.querySelector('.js-day-bt-footer > svg').classList.toggle('u-rotate-180');
          }
        });

        if (!day.classList.contains('js-day-bt--open')) {
          day.classList.add('js-day-bt--open');

          if (typeof offset === 'undefined' || dayIndex < prevDayIndex) {
            offset = 0;
          }

          if (window.matchMedia('only screen and (max-width: 1023px)').matches) {
            scrollTo('.js-day-bt--open', 700, parseInt(offset, 10) + 100);
          }
        } else {
          day.classList.remove('js-day-bt--open');
        }

        prevDayIndex = dayIndex;

        day.querySelector('.js-day-bt-footer > svg').classList.toggle('u-rotate-180');
      });
    }
  });
}

function togglePriceBlock(el) {
  toggleSlide(document.querySelector(`${el}-content`));
  document.querySelector(`${el} > svg`).classList.toggle('u-rotate-0');
}

if (document.querySelector('.js-price')) {
  document.querySelector('.js-price').addEventListener('click', () => {
    togglePriceBlock('.js-price');
  });

  document.querySelector('.js-price-include').addEventListener('click', () => {
    togglePriceBlock('.js-price-include');
  });

  document.querySelector('.js-price-exclude').addEventListener('click', () => {
    togglePriceBlock('.js-price-exclude');
  });
}
