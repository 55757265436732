import Noty from 'noty';

export function setError(el) {
  el.classList.add('bg-error');
}

export function removeError(el) {
  el.addEventListener('input', () => {
    el.classList.remove('bg-error');
  });
}

export function showMessage(msgType, text) {
  new Noty({
    type: msgType,
    layout: 'bottomRight',
    timeout: 3500,
    theme: 'metroui',
    text: `${text}`
  }).show();
}

export function showSuccess(text) {
  showMessage('success', text);
}

export function showError(text) {
  showMessage('error', text);
}

export function htmlToElements(html) {
  const template = document.createElement('template');
  template.innerHTML = html;
  return template.content.childNodes;
}
